import React from "react";
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../styles/components/about.scss";

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="About" />
    <div className="about">
      <h1 className="about__title">About</h1>
      <div className="about__content">
        <Img
          fluid={data.file.childImageSharp.fluid}
          className="about__img"
          alt="Stuart Hazley"
        />
        <div className="about__text">
          <p>Hi, my name is Stuart Hazley and welcome to my little independent Type Foundry.</p>
          <p>I’m a Typeface designer living in Northern Ireland, and I have been involved within the type industry for the last 5 years.
          Firstly, as part of the MyFonts Customer Support team, and now having gone out on my own as a designer. The Ostent font family
          marks my first commercial release (released 2019).</p>
          <p>As well as offering commercial fonts (as displayed on this site) I am also available for custom Type work. For enquiries, 
          please contact me <Link to='/contact'>here</Link>.</p>
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    file(relativePath: {eq: "shazley.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 310) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default AboutPage;
